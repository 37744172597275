<template>
  <slot />
</template>

<script>
export default {
  props: {
    visible: Boolean,
    role: Object,
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    async onConfirm() {
      try {
        this.loading = true

        await this.$http.delete(`/publications/${ this.$store.state.publication.data.id }/roles/${ this.role.id }`)

        this.$message.success('Role has been deleted.')
        this.$emit('success')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.$emit('cancel')
        this.loading = false
      }
    },
  },

  watch: {
    visible(value) {
      if (value === true) {
        this.$confirm({
          title: 'Delete Role',
          content: `You are permanently deleting the role “${ this.role.title }” from your publication. The action cannot be undone.`,
          okText: 'Delete',
          okType: 'danger',
          confirmLoading: this.loading,
          onOk: async () => {
            await this.onConfirm()
          },
          onCancel: () => {
            this.$emit('cancel')
          },
        })
      } else {
        this.$emit('cancel')
      }
    },
  },
}
</script>
