<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6 mb-4 mb-md-0">
        <h2 class="h6">{{ role.title }}</h2>
        <div v-if="role.admin" class="small text-muted mb-1">
          Administrators have unrestricted permissions and can manage the entire publication.
        </div>
        <div v-if="role.everyone" class="small text-muted mb-1">
          Everyone has at least the permissions set here, even if assigned user roles have less.
        </div>
        <a-tag v-if="role.usersCount">{{ role.usersCount }} Users</a-tag>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <p class="text-overline">Edited</p>
        <div>
          <a-tooltip :title="dateFormatted(role.updatedAt)" placement="bottom">
            {{ dateFromNow(role.updatedAt) }}
          </a-tooltip>
        </div>
      </div>
      <div class="col-12 col-md-2 text-md-end">
        <a-dropdown-button
          size="small"
          :trigger="['click']"
          :disabled="!$store.getters['publication/isAdmin'] || role.admin"
          @click="visible.update = true"
        >
          <RoleDrawer
            :visible="visible.update"
            :role="role"
            @cancel="visible.update = false"
            @success="onSuccess('update')"
          >
            Edit
          </RoleDrawer>
          <template #overlay>
            <a-menu @click="onMenuClick">
              <a-menu-item class="text-danger" :disabled="role.everyone" key="delete">
                <RoleDelete
                  :visible="visible.delete"
                  :role="role"
                  @cancel="visible.delete = false"
                  @success="onSuccess('delete')"
                >
                  <TrashIcon class="icon me-2" />Delete
                </RoleDelete>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown-button>
      </div>
    </div>
  </div>
</template>

<script>
import { TrashIcon } from '@heroicons/vue/24/outline'
import moment from 'moment'
import RoleDelete from '@/components/Role/RoleDelete'
import RoleDrawer from '@/components/Role/RoleDrawer'

export default {
  components: {
    RoleDelete,
    RoleDrawer,
    TrashIcon,
  },

  props: {
    role: Object,
  },

  data() {
    return {
      visible: {
        update: false,
        delete: false,
      },
    }
  },

  methods: {
    dateFromNow(date) {
      return moment(date).fromNow()
    },
    dateFormatted(date) {
      return moment(date).format('LLLL')
    },
    onSuccess(key) {
      this.visible[key] = false
      this.$emit('change')
    },
    onMenuClick(e) {
      this.visible[e.key] = true
    },
  },
}
</script>
