<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h1 class="h3 mb-0">
      Roles <small v-if="rolesCount !== null" class="text-muted">({{ rolesCount }})</small>
    </h1>
    <RoleDrawer
      :visible="visible"
      @cancel="visible = false"
      @success="listRoles"
    >
      <a-button type="primary" shape="circle" size="large" :disabled="loading" @click="visible = true">
        <PlusIcon class="icon" />
      </a-button>
    </RoleDrawer>
  </div>
  <BaseSpinner :loading="loading">
    <RoleItem
      v-for="(role, i) in roles"
      :role="role"
      class="py-3"
      :class="{ 'border-bottom': i < rolesCount - 1 }"
      @change="listRoles"
    />
  </BaseSpinner>
  <BasePagination
    class="mt-4"
    v-model="filters.page"
    :hasMore="hasMore"
    :pageCount="Math.ceil(rolesCount / filters.limit)"
  />
</template>

<script>
import { PlusIcon } from '@heroicons/vue/24/outline'
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'
import RoleDrawer from '@/components/Role/RoleDrawer'
import RoleItem from '@/components/Role/RoleItem'

export default {
  components: {
    BasePagination,
    BaseSpinner,
    PlusIcon,
    RoleDrawer,
    RoleItem,
  },

  data() {
    return {
      loading: true,
      visible: false,
      hasMore: false,
      roles: [],
      rolesCount: null,
      filters: {
        limit: 20,
        page: Number(this.$route.query.page) || 1,
      },
    }
  },

  async mounted() {
    await this.listRoles()
  },

  methods: {
    async listRoles() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/publications/${ this.$store.state.publication.data.id }/roles`, {
          params: this.filters,
        })

        this.roles = data.roles
        this.rolesCount = data.rolesCount
        this.hasMore = data.hasMore
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
  },

  watch: {
    'filters.page'(value) {
      this.$router.push({ name: 'publications-id-settings-roles', query: { ...this.$route.query, page: value }})
    },
  },
}
</script>
