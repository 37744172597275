<template>
  <slot />
  <a-drawer
    :title="`${ role ? 'Edit' : 'Add' } Role`"
    :width="560"
    @close="$emit('cancel')"
    :visible="visible"
  >
    <a-form
      :colon="false"
      layout="vertical"
      :model="values"
      ref="form"
      @finish="onFinish"
    >
      <a-form-item
        label="Title"
        name="title"
        :rules="[
          { required: true, message: 'Title is required.' },
        ]"
      >
        <a-input
          v-model:value="values.title"
          :maxlength="50"
          name="title"
          :disabled="role ? role.everyone : false"
        />
      </a-form-item>
      <table class="table table-bordered w-100">
        <thead>
          <tr>
            <th class="w-75">Permission</th>
            <th class="text-end">Own</th>
            <th class="text-end">All</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" class="align-middle">
            <td>
              <p class="mb-1"><strong>{{ item.title }}</strong></p>
              <p class="text-muted mb-0">{{ item.description }}</p>
            </td>
            <template v-if="item.permissions.length > 1">
              <td class="text-end">
                <a-switch
                  :checked="isChecked(item.permissions[0])"
                  :name="item.permissions[0]"
                  size="small"
                  @change="e => onChange(e, item.permissions[0])"
                />
              </td>
              <td class="text-end">
                <a-switch
                  :checked="isChecked(item.permissions[1])"
                  :name="item.permissions[1]"
                  size="small"
                  @change="e => onChange(e, item.permissions[1])"
                />
              </td>
            </template>
            <template v-else>
              <td class="text-end" colspan="2">
                <a-switch
                  :checked="isChecked(item.permissions[0])"
                  :name="item.permissions[0]"
                  size="small"
                  @change="e => onChange(e, item.permissions[0])"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </a-form>
    <template #extra>
      <a-button type="primary" html-type="submit" :loading="loading" @click="onFinish">
        Save
      </a-button>
    </template>
  </a-drawer>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    role: Object,
  },

  data() {
    return {
      loading: false,
      values: {
        title: this.role && this.role.title,
        permissions: this.role ? this.role.permissions : [],
      },
    }
  },

  computed: {
    items() {
      return [{
        title: 'Edit unreviewed articles',
        description: 'Can edit articles until passed on for review.',
        permissions: ['article', 'article.all'],
        status: 'drafted',
      }, {
        title: 'Edit reviewed articles',
        description: 'Can make editorial reviews until published.',
        permissions: ['article.edited', 'article.edited.all'],
        status: 'edited',
      }, {
        title: 'Edit published articles',
        description: 'Can edit articles after they are published.',
        permissions: ['article.published', 'article.published.all'],
        status: 'published',
      }, {
        title: 'Edit call-to-actions',
        description: 'Can manage publication’s call-to-actions.',
        permissions: ['action'],
      }, {
        title: 'Edit sections',
        description: 'Can manage publication’s sections.',
        permissions: ['section'],
      }, {
        title: 'Edit users',
        description: 'Can manage publication’s users.',
        permissions: ['user'],
      }]
    },
  },

  methods: {
    isChecked(permission) {
      return !!this.values.permissions.find(item => item === permission)
    },
    onChange(e, permission) {
      if (e) {
        this.values.permissions.push(permission)
      } else {
        this.values.permissions = this.values.permissions.filter(item => item !== permission)
      }
    },
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        if (this.role) {
          await this.$http.put(`/publications/${ this.$store.state.publication.data.id }/roles/${ this.role.id }`, {
            ...values,
            permissions: [...new Set(this.values.permissions)],
          })
        } else {
          await this.$http.post(`/publications/${ this.$store.state.publication.data.id }/roles`, {
            ...values,
            permissions: [...new Set(this.values.permissions)],
          })
        }

        this.$message.success('Role has been saved.')
        this.$emit('success')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
